<template>
  <div class="club-list d-flex flex-column">
    <v-row v-if="clubs.length" class="justify-end align-center mx-4 mt-6 mb-4">
      <export-to-csv
        :file-name-preffix="`${project.name} - ${$t(`competition.sections.clubs`)}`"
        action-name="competition/getClubsCsv"
        :action-params="{ organizationId, projectId }"
        :button-text="`${$t(`common.export`)} ${$t(`competition.sections.clubs`)}`"
      />
      <export-to-csv
        :file-name-preffix="`${project.name} - ${$t(`common.teams`)}`"
        action-name="competition/getTeamsCsv"
        :action-params="{ organizationId, projectId }"
        :button-text="`${$t(`common.export`)} ${$t(`common.teams`)}`"
      />
      <export-to-csv
        :file-name-preffix="`${project.name} - ${$t(`common.players`)}`"
        action-name="competition/getPlayersCsv"
        :action-params="{ organizationId, projectId }"
        :button-text="`${$t(`common.export`)} ${$t(`common.players`)}`"
      />
      <v-text-field
        v-model="search"
        class="flex-grow-0 ml-3"
        :label="$t('common.search')"
        prepend-inner-icon="search"
        tag="span"
        outlined
        dense
        hide-details
      />
    </v-row>
    <!-- Table -->
    <v-data-table v-if="clubs.length"
                  :headers="headers" :items="clubs"
                  :search="search" dense
                  class="table--has-pointer" @click:row="(club)=> $router.push({ name: 'club', params: { clubId: club.id } })"
    >
      <template #[`item.logo`]="{ item }">
        <avatar-field :value="item.logo" :active="false" :is-square="true" custom-size="48" custom-icon="none" class="pa-2" />
      </template>

      <template #[`item.staffName`]="{ item }">
        <multiple-labels :labels="item.staffNameLabels" />
      </template>

      <template #[`item.staffEmail`]="{ item }">
        <copy-text :text="item.staffEmail" />
      </template>

      <template #item.lastModification="{ item }">
        <div v-if="item.lastModification" :style="{ color: getLastModification(item.lastModification).color }">
          <multiple-labels :labels="item.lastModificationLabels" />
        </div>
      </template>

      <template #item.status="{ item }">
        <v-chip label outlined :color="getPaymentStatusColor(item.status)" v-text="$t(`club.payments.${item.status}`)" />
      </template>
    </v-data-table>
    <empty-list v-else :content="$t('competition.sections.clubs')" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'
import lodashGet from 'lodash/get'
import dateFilter from '@/utils/mixins/dateFilter'
import dataTables from '@/enums/dataTables'

export default {
  name: 'ClubList',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
    ExportToCsv: () => import('@/components/ExportToCsv'),
    AvatarField: () => import('@/components/AvatarField'),
  },
  mixins: [dateFilter],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      tab: null,
      search: '',
      sortBy: 'name',
      sortDesc: false,
      clubs: [],
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ dbClubs: 'competition/clubs' }),
    ...mapGetters({ hasPaymentFeature: 'project/hasClubPaymentFeature' }),
    headers({ hasPaymentFeature }) {
      return [
        { text: this.$t('club.fields.logo'), value: 'logo' },
        { text: this.$t('club.fields.name'), value: 'name' },
        { text: this.$t('club.fields.staffName'), value: 'staffName' },
        { text: this.$t('club.fields.staffPhone'), value: 'staffPhone' },
        { text: this.$t('club.fields.staffEmail'), value: 'staffEmail' },
        { text: this.$t('club.fields.lastModification'), value: 'lastModification' },
        ...(hasPaymentFeature ? [{ text: this.$t('club.payments.title'), value: 'status' }] : []),
      ]
    },
    settingsDataTable() {
      return dataTables
    },
  },
  watch: {
    dbClubs(dbClubs) { this.getClubs(dbClubs) },
  },
  async created() {
    await this.getClubs(this.dbClubs)
    if (this.$route.meta.scrollYPosition) window.scrollTo(0, this.$route.meta.scrollYPosition)
  },
  methods: {
    ...mapActions('club', ['archive', 'unarchive', 'createClone']),
    async getClubs(dbClubs) {
      this.$store.commit('loader/show')
      this.clubs = await Promise.all(dbClubs.map(async club => {
        const { organizationId, projectId } = this
        const staff = club.staff
        const managerUpdatesSortedEntries = Object.entries(club?.managerUpdates ?? {}).sort(([aKey, aVal], [bKey, bVal]) => bVal.seconds - aVal.seconds)
        return {
          ...club,
          staffNameLabels: staff?.map(e => `${e.firstName} ${e.lastName}`),
          staffName: lodashGet(staff, '0.firstName', ''),
          staffPhone: `+${lodashGet(staff, '0.phone.code', '')} ${lodashGet(staff, '0.phone.number', '')}`,
          staffEmail: lodashGet(staff, '0.email', ''),
          lastModification: managerUpdatesSortedEntries.map(([key, value]) => value).find(e => true),
          lastModificationLabels: managerUpdatesSortedEntries.map(([key, value]) => `${this.formatDate(value)} - ${this.$t(`club.managerUpdates.${key}`)}`),
          status: club.paymentStatus,
        }
      }))
      this.clubs = this.clubs.sort((a, b) => b?.createdAt?.toDate() - a?.createdAt?.toDate())
      this.$store.commit('loader/hide')
    },
    // Helpers
    getPaymentStatusColor: status => ({
      notRequested: 'gray',
      requested: '#ff8f45',
      complete: 'success',
    }[status]),
    getLastModification(updatedAt) {
      const updatedAtInSeconds = DateTime.fromSeconds(updatedAt.seconds, { zone: this.project.timezone })
      const currentTimeInSeconds = DateTime.fromSeconds(new Date().getTime() / 1000, { zone: this.project.timezone })
      const diff = currentTimeInSeconds.diff(updatedAtInSeconds, ['hours'])
      if (diff.values.hours > 48) {
        return { color: this.$vuetify.theme.themes.light.archived }
      }
      return { color: this.$vuetify.theme.themes.light.warningDark }
    },
  },
}
</script>

<style lang="scss" scoped>
.club-list {
  &__actions-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 70px;
    background: whitesmoke;
  }
}

.v-avatar img {
  width: 100%;
  object-fit: cover;
}
</style>
